import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import "../../assets/sass/articles.scss";

const PromoteWebsitePl = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Jak stworzyć stronę" language="pl"/>
            <article className="article">
                <div className="container">
                    <div className="article__header">
                        <HorizontalLine classname="white"/>
                        <h1 className="article__header--title">Jak zbudować stronę firmową?</h1>
                        <HorizontalLine classname="white"/>
                        <h2 className="article__header--subtitle">
                            Wirtualna wizytówka Twojej własnej firmy to niezwykle istotna rzecz w dzisiejszych
                            czasach. Taka właściwie zrobiona uwiarygadnia wizerunek firmy, skutecznie
                            przyciąga klientów a nawet sprzedaje. Jednak aby strona była atrakcyjna dla
                            potencjalnego klienta musi spełniać kilka niezbędnych warunków. Dziś
                            podpowiadamy jak zrobić minimalistyczną, przejrzystą i obfitującą w niezbędne
                            informacje stronę firmową.
                        </h2>
                    </div>
                    <ol className="article__list--numbered">
                        <li className="article__section--title">Domena i hosting dla firm.</li>
                        <p className="article__text">
                            Pierwszym i najważniejszym krokiem, który doprowadzi Cię do firmowej strony
                            internetowej jest wykreowanie chwytliwej nazwy domeny wraz z jej rozszerzeniem.
                            Najpopularniejszym rozwiązaniem jest prosta nazwa firmy wraz z końcówką “pl”,
                            jednak bardzo często tego typu rozszerzenia są po prostu zajęte, wówczas
                            pozostają do wyboru nieco inne, ale to nie znaczy, że gorsze końcówki.
                            Zaraz po wyborze domeny, pozostaje kwestia hostingu dla firm. Najprościej mówiąc,
                            hosting to miejsce, w którym będą przechowywane wszystkie pliki z firmowej strony
                            internetowej. Hosting z powodzeniem możesz kupić/zamówić tam, gdzie kupiłeś
                            domenę.
                        </p>

                        <li className="article__section--title">Odpowiedni wygląd strony internetowej.</li>
                        <p className="article__text">
                            Pewnie niejednokrotnie zastanawiałeś się jak właściwie powinna wyglądać firmowa
                            strona internetowa? Przede wszystkim powinna idealnie odzwierciedlać branżę, w
                            której pracujesz. W zależności od dziedziny, strona powinna intrygować, wzbudzać
                            zaufanie, czy wywoływać określone stany emocjonalne.
                        </p>
                        <br/>
                        <p className="article__text">
                            To co powyżej z łatwością osiągniesz odpowiednimi zdjęciami i chwytliwymi
                            sloganami. Pamiętaj, że autentyczność przyciąga dość mocno. Zrezygnuj od razu z
                            gotowych zdjęć ze Stocku, ponieważ stosunkowo łatwo rozpoznać je na stronie i
                            takowe fotografie nie budzą zaufania. Zadbaj też o rzucające się w oczy logo, bo to
                            jeden z elementów, który skutecznie skupia na sobie wzrok.
                        </p>
                        <br/>
                        <p className="article__text">
                            Cały wygląd swojej strony zdefiniujesz odpowiednim szablonem, który będzie
                            swoistą bazą Twojego projektu. Przy wyborze motywu, nie zapomnij o
                            użytkownikach smartfonów, czy też tabletów. Strona powinna automatycznie
                            dostosować się do ekranów wszystkich urządzeń, na których możliwe jest
                            przeglądanie witryn internetowych.
                        </p>
                        <li className="article__section--title">Transparentne menu strony.</li>
                        <p className="article__text">
                            Menu na stronie internetowej umożliwia internautom, znalezienie tego, czego
                            szukają. Budowanie tego działu to szansa na to, by dowiedzieć się czego ludzie
                            zamierzają szukać na Twojej stronie.
                        </p>
                        <li className="article__section--title">Strona główna.</li>
                        <p className="article__text">
                            Uzbrojona w argumenty i CTA. Taka, która jest kształtowana aktywnie i najmocniej
                            przyciągnie uwagę internauty. Postaw również na jasne a przede wszystkim zwięzłe
                            komunikaty, które wzmacniają chęć przeglądania innych zakładek na stronie. W
                            dalszej części strony głównej sugerujemy umieszczenie opisu firmy wraz z frazami,
                            na które planowane jest pozycjonowanie.
                        </p>
                        <li className="article__section--title">Bogata oferta firmy/portfolio.</li>
                        <p className="article__text">
                            Teraz masz szansę by poćwiczyć formę swojej treści i nie tylko. Oferta, to coś, co
                            potencjalny klient na pewno będzie chciał zobaczyć. Coś, po czym być może
                            wybierze Twoją firmę do realizacji jakiegoś przedsięwzięcia. Projektując zakładkę z
                            ofertą postaw nawet na najdrobniejsze szczegóły oraz zdjęcia a nawet filmy, które
                            pokażą profesjonalizm.
                        </p>
                        <li className="article__section--title">Linki do kont w mediach społecznościowych.</li>
                        <p className="article__text">
                            Jeśli posiadasz firmowe konto na jednym bądź kilku portalach social media, nic nie
                            stoi na przeszkodzie, by umieścić link do nich na Twojej stronie internetowej,
                            zwłaszcza, że współpraca między witryną a kanałami social media, może się
                            świetnie układać.
                        </p>
                        <li className="article__section--title">Formularze kontaktowe i skuteczne wezwanie do
                            działania.
                        </li>
                        <p className="article__text">
                            Tworząc profesjonalną stronę warto zadbać o innowacyjny formularz kontaktowy i
                            chwytliwe CTA. Analizując najnowsze trendy, można by rzec, że wśród stron
                            firmowych i nie tylko królują integracje strony z Messengerem, livechat’y bądź
                            komunikaty umożliwiające klientowi zamówienie kontaktu z danej firmy. Wszystkie
                            bez wyjątku mają na celu sfinalizowanie określonej transakcji.
                        </p>
                    </ol>
                    <h3 className="article__section--title text-center">Strona internetowa z nakazem wstępu</h3>
                    <blockquote>
                        <p>
                            Firmowa strona internetowa to wizytówka Ciebie i Twojego biznesu. To strona, która
                            buduje nie tylko prestiż, ale i autorytet. Wygodna, nowoczesna a przede wszystkim
                            funkcjonalna strona stwarza dogodne warunki do aktywnego poszukiwania informacji
                            odnośnie profilu działalności biznesu. Skonstruowanie strony internetowej powierza
                            się <strong>specjalistom</strong> w dziedzinie web design, którzy pieczołowicie połączą
                            kreację z
                            intuicyjnością.
                        </p>
                    </blockquote>
                </div>
            </article>
        </div>
    );
};
export default PromoteWebsitePl;